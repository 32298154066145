.page-header .left > * {
    margin: unset;
}

.page-header .left .back-link {
    display: flex;
    align-items: center;
}

.page-header .left .back-link::before {
    content: "←";
    padding-right: 8px;
}

.page-header .left .back-link,
.page-header .left .back-link::before {
    color: black;
    text-decoration: unset;
    font-size: 1.5em;
}

.page-body .btn-download {
    text-decoration: underline;
}

.page-body .btn-download,
.page-body .btn-clear {
    cursor: pointer;
}
