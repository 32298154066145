.page-body .btn-download {
    text-decoration: underline;
    cursor: pointer;
}

.new-edit-modal .sub-questions > div,
.new-edit-modal .sub-questions > table {
    padding-left: 25px;
}

.new-edit-modal .sub-questions > table {
    display: inline-block;
}

.new-edit-modal .sub-questions .btn-clear {
    cursor: pointer;
}
