.date-picker-ctn {
    display: inline-block;
    position: relative;
}

.date-picker-ctn > label {
    min-width: 185px;
    min-height: 30px;
    padding: 1px 2px;
    cursor: pointer;
}

.date-picker {
    position: absolute;
    z-index: 10;
}

.date-picker-ctn > label,
.date-picker {
    border: 1px #767676 solid;
    border-radius: 3px;
}

.date-picker-ctn > label.disabled {
    cursor: default;
    background-color: rgba(59, 59, 59, 0.3);
    color: rgb(170, 170, 170);
    border-color: rgba(118, 118, 118, 0.3);
}

.date-picker > * {
    display: inline-block;
    background-color: white;
    width: 100%;
}

.date-picker > span {
    display: flex;
    justify-content: center;
    padding-top: 1em;
    text-decoration: underline;
    cursor: pointer;
}

.hidden {
    display: none;
}