.rc-time-picker > input {
    min-width: 185px;
    min-height: 30px;
    padding: 1px 2px;
    font-size: 16px;
    color: black;
    border: 1px #767676 solid;
    border-radius: 3px;
}

.rc-time-picker > input[disabled] {
    background-color: rgba(59, 59, 59, 0.3);
    color: rgb(170, 170, 170);
    border-color: rgba(118, 118, 118, 0.3);
}