body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  margin-bottom: 8px;
}

.page-header > * {
  display: inline-block;
  margin: unset;
}

.page-header .right {
  display: flex;
}

.page-body {
  overflow-x: scroll;
}

.page-body table {
  border-collapse: separate;
}

.table-headers th, .table-row td {
  min-width: 10em;
  padding: 15px 20px 15px 10px;
  text-align: left;
}

.table-headers th {
  background-color: dodgerblue;
  color: white;
}

.table-row td {
  background-color: lightblue;
}
