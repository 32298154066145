.date-range-picker-ctn {
    display: inline-block;
    position: relative;
}

.date-range-picker-ctn > label {
    min-width: 185px;
    min-height: 30px;
    padding: 1px 1em;
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: space-evenly;
}

.date-range-picker-ctn > label.my-placeholder {
    color: grey;
}

.date-range-picker {
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, 0);
}

.date-range-picker-ctn > label,
.date-range-picker {
    border: 1px #767676 solid;
    border-radius: 3px;
}

.date-range-picker > * {
    display: inline-block;
    background-color: white;
    width: 100%;
}

.date-range-picker > div.btn-ctn {
    padding-top: 0.833em;
    display: flex;
    justify-content: space-evenly;
}

.date-range-picker > div > span {
    display: flex;
    justify-content: center;
    text-decoration: underline;
    cursor: pointer;
}

.date-range-picker > div > span.disabled {
    color: rgb(170, 170, 170);
    cursor: default;
}

.hidden {
    display: none;
}