.sortable-th {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
}

.sortable-th .arrows {
    position: relative;
    display: inline-block;
}

.sortable-th .arrows::before {
    position: absolute;
    bottom: 1px;
    display: inline-block;
    margin: 0 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid lightgrey;
    border-left: 0.3em solid transparent;
}

.sortable-th.asc .arrows::before {
    border-bottom: 0.3em solid;
}

.sortable-th .arrows::after {
    position: absolute;
    top: 1px;
    display: inline-block;
    margin: 0 0.255em;
    content: "";
    border-top: 0.3em solid lightgrey;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.sortable-th.desc .arrows::after {
    border-top: 0.3em solid;
}