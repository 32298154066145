.search-dropdown.my-placeholder > button {
    color: rgb(170, 170, 170) !important;
}

.search-dropdown .search-ctn {
    display: flex;
    align-items: center;
}

.search-dropdown .search-ctn > input {
    position: relative;
    width: 100%;
}

.search-dropdown .search-ctn > .btn-clear {
    position: absolute;
    right: 0;
    padding-right: 5px;
    cursor: pointer;
}
