.invoice,
.invoice > * {
    width: 100%;
}

.invoice .download-btn {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #e8e8e8;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 10;
}

.invoice .download-btn img {
    width: 40%;
}

.invoice ul {
    list-style-type: none;
    padding: unset;
    margin: unset;
}

.invoice .amount-large {
    padding: 5px 20px 5px 30px;
    border-top-left-radius: 30px;
    background-color: lightgrey;
    font-size: xx-large;
    font-weight: bold;
}

.invoice .title {
    font-weight: bold;
    color: black;
}

.invoice .amount-large,
.invoice .right-align {
    padding-right: 20px !important;
}

.invoice .invoice-header-space,
.invoice .invoice-footer-space {
    height: 0;
    padding: unset;
}

.invoice .invoice-header .invoice-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.invoice .invoice-header .invoice-banner > * {
    padding: 20px;
}

.invoice .invoice-header .invoice-banner img {
    max-width: 200px;
    margin-left: 5%;
}

.invoice .invoice-header .invoice-banner .right-ctn h1 {
    font-weight: 900;
}

.invoice .invoice-header .invoice-banner .right-ctn,
.invoice .invoice-header .invoice-banner .right-ctn ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.invoice .invoice-header .info-ctn * {
    margin: unset;
}

.invoice .invoice-header .info-ctn {
    position: relative;
    background-color: #F6F6F6;
    min-height: 80px;
}

.invoice .invoice-header .info-ctn > hr {
    border: 1px solid black;
    color: black;
    position: absolute;
    width: 100%;
    top: 30px;
}

.invoice .invoice-header .info-ctn .info {
    display: flex;
    justify-content: space-around;
    position: absolute;
    width: 100%;
    top: 20px;
}

.invoice .invoice-header .info-ctn .info .info-block {
    padding: 0 20px;
    background-color: #F6F6F6;
}

.invoice .invoice-header .info-ctn .info .info-block::before {
    content: "●";
    padding-right: 5px;
}

.invoice .invoice-header .info-ctn .info .info-block .right-ctn {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
}

.invoice .invoice-body .contact-ctn {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 5px solid #E5E5E5;
}

.invoice .invoice-body .contact-ctn .contact-block {
    display: flex;
    flex-direction: column;
}

.invoice .invoice-body .contact-ctn .contact-block > img {
    width: 20px;
}

.invoice .invoice-body .notes-ctn * {
    margin: unset;
}

.invoice .invoice-body .notes-ctn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    border-bottom: 1px solid #E5E5E5;
}

.invoice .invoice-body .notes-ctn > * {
    display: flex;
    flex-direction: column;
}

.invoice .invoice-body .notes-ctn .right-ctn > span {
    padding-left: 30px;
}

.invoice .invoice-body .items-ctn table {
    width: 100%;
}

.invoice .invoice-body .items-ctn table th,
.invoice .invoice-body .items-ctn table td {
    padding: 10px 5px;
    border-bottom: 1px solid #E5E5E5;
}

.invoice .invoice-body .items-ctn table tbody tr:nth-child(odd) {
    background-color: #F6F6F6;
}

.invoice .invoice-body .contact-ctn,
.invoice .invoice-footer .top-ctn,
.invoice .invoice-body .notes-ctn .left-ctn,
.invoice .invoice-body .items-ctn table th:first-child,
.invoice .invoice-body .items-ctn table td:first-child {
    padding-left: 10%;
}

.invoice .invoice-body .contact-ctn,
.invoice .invoice-footer .top-ctn,
.invoice .invoice-body .items-ctn table th:last-child,
.invoice .invoice-body .items-ctn table td:last-child {
    padding-right: 10%;
}

.invoice .invoice-footer .top-ctn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}

.invoice .invoice-footer .top-ctn .left-ctn {
    color: #BDBEBE;
}

.invoice .invoice-footer .top-ctn .right-ctn > div {
    display: inline-flex;
    flex-direction: column;
    padding: 0 5px;
}

.invoice .invoice-footer .top-ctn .right-ctn .titles {
    align-items: flex-end;
}

.invoice .invoice-footer .top-ctn .right-ctn .titles .hst-num {
    color: lightgrey;
}

.invoice .invoice-footer .top-ctn .right-ctn .titles .hst-num > .title {
    padding-left: 20px;
}

.invoice .invoice-footer .bottom-ctn {
    display: flex;
    justify-content: flex-end;
}

@media print {
    .invoice {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    .invoice .download-btn {
        display: none;
    }

    .invoice .invoice-header {
        position: fixed;
        top: 0;
    }

    .invoice .invoice-header,
    .invoice .invoice-header-space {
        height: 245px;
    }

    .invoice .invoice-footer {
        position: fixed;
        bottom: 0;
    }

    .invoice .invoice-footer,
    .invoice .invoice-footer-space {
        height: 126px;
    }
}

@page {
    margin: 2mm;
}
